import React, { useEffect } from 'react';

export const TextInput = ({ name, placeholder, errors, register, setValue, value: externalValue, ...props }) => {
    useEffect(() => {
        if(externalValue){
            setValue(name, externalValue);
        }
    },  [externalValue, setValue, name]);

    return (
        <div className={`form-group ${props.small && 'col-6'}`}>
            <input
                onChange={(e) => setValue(name, e.target.value)}
                className={props.className}
                {...register(name)}
                type={props?.type || 'text'}
                placeholder={placeholder}
                name={name} id={name}
            />
            <p className='error'>{errors[name]?.message}</p>
        </div>
    )
}

