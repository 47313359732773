import React, { useEffect } from 'react';

export const SelectInput = ({ name,errors, register, setValue, value: externalValue, children, ...props }) => {
    useEffect(() => {
        if (externalValue) {
            setValue(name, externalValue);
        }
    }, []);

    const handleChange = (e) => {
        setValue(name, e.target.value);
    };

    return (
        <div className="form-group">
            <select
                className="form-control"
                id={name}
                name={name}
                onChange={handleChange}
                {...register(name)}
                aria-describedby={name}
                {...props}
            >
                {children}
            </select>
            <p className="error">{errors[name]?.message}</p>
        </div>
    );
};

