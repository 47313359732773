import React from "react";
export const SvgComponent = ({ title, handlePlate, ...props }) => {

    return (

        <div className="svgContainer">
            <h2>{title}</h2>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 798.16 860.92"
                {...props}
            >
                <defs>
                    <style>
                        {
                            "\n      .cls-1 {\n        fill: #fff;\n      }\n\n      .cls-1, .cls-2 {\n        stroke: #000;\n        stroke-miterlimit: 10;\n      }\n\n      .cls-2 {\n        fill: none;\n      }\n    "
                        }
                    </style>
                </defs>
                <g>
                    <line className="cls-2" x1={110.08} y1={85.55} x2={111.28} y2={860.92} />
                    <path d="m110.8,89.84c-2.35-9.6-5.9-18.91-10.68-27.57-4.31-7.83-9.61-15.12-15.77-21.61-5.66-5.96-12.07-11.25-19.06-15.58-9.17-5.67-19.24-9.72-29.6-12.62-10.23-2.87-20.71-4.66-31.19-6.31-1.29-.2-2.58-.4-3.87-.6-.63-.1-.9.87-.27.96,10.36,1.61,20.74,3.24,30.91,5.8,10.45,2.64,20.66,6.31,30.07,11.6,7.29,4.1,13.99,9.18,19.95,15.05,6.28,6.19,11.73,13.2,16.23,20.79,4.94,8.32,8.73,17.31,11.33,26.63.34,1.23.67,2.47.97,3.71.17.64,1.14.38.98-.25h0Z" />
                </g>
                <g id="Capa_3" data-name="Capa 3">
                    <path
                        className="cls-1"
                        d="m663.3,811.44c7.94.12,12.24-3.89,15.35-8.39v-339.8c-1.07-2.59-2.42-5.12-4.43-7.34-4.28-4.74-11.08-5.59-18.64-5.45-1.06.02-1.98-.11-2.77-.35H187.07c-9.87,3.73-19.11,10.05-23.07,16.84v327.08c.36.54.58,1.17.59,1.88.27,8.7,7.58,13.08,17.01,15.64h480.03c.5-.08,1.07-.13,1.67-.12h0Z"
                    />
                    <ellipse className="cls-1" cx={188.07} cy={463.85} rx={7.68} ry={5.39} />
                    <ellipse className="cls-1" cx={655.51} cy={463.85} rx={7.68} ry={5.39} />
                    <ellipse className="cls-1" cx={188.07} cy={798.57} rx={7.68} ry={5.39} />
                    <ellipse className="cls-1" cx={655.51} cy={798.57} rx={7.68} ry={5.39} />
                </g>
                <g id="Capa_4" data-name="Capa 4">
                    <g>
                        <line className="cls-2" x1={164.06} y1={741.4} x2={190.97} y2={741.4} />
                        <line
                            className="cls-2"
                            x1={190.97}
                            y1={741.4}
                            x2={190.97}
                            y2={735.85}
                        />
                        <line
                            className="cls-2"
                            x1={190.97}
                            y1={735.85}
                            x2={164.06}
                            y2={728.18}
                        />
                        <line
                            className="cls-2"
                            x1={164.22}
                            y1={749.82}
                            x2={191.11}
                            y2={749.82}
                        />
                        <line
                            className="cls-2"
                            x1={191.11}
                            y1={749.82}
                            x2={191.11}
                            y2={755.36}
                        />
                        <line
                            className="cls-2"
                            x1={191.11}
                            y1={755.36}
                            x2={164.22}
                            y2={763.04}
                        />
                        <line
                            className="cls-2"
                            x1={164.14}
                            y1={679.04}
                            x2={191.04}
                            y2={679.04}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={679.04}
                            x2={191.04}
                            y2={673.49}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={673.49}
                            x2={164.14}
                            y2={665.82}
                        />
                        <line
                            className="cls-2"
                            x1={164.28}
                            y1={687.46}
                            x2={191.19}
                            y2={687.46}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={687.46}
                            x2={191.19}
                            y2={693.01}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={693.01}
                            x2={164.28}
                            y2={700.68}
                        />
                        <line className="cls-2" x1={164.14} y1={618.5} x2={191.04} y2={618.5} />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={618.5}
                            x2={191.04}
                            y2={612.95}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={612.95}
                            x2={164.14}
                            y2={605.28}
                        />
                        <line
                            className="cls-2"
                            x1={164.28}
                            y1={626.92}
                            x2={191.19}
                            y2={626.92}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={626.92}
                            x2={191.19}
                            y2={632.47}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={632.47}
                            x2={164.28}
                            y2={640.14}
                        />
                        <line
                            className="cls-2"
                            x1={164.14}
                            y1={557.95}
                            x2={191.04}
                            y2={557.95}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={557.95}
                            x2={191.04}
                            y2={552.4}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={552.4}
                            x2={164.14}
                            y2={544.73}
                        />
                        <line
                            className="cls-2"
                            x1={164.28}
                            y1={566.37}
                            x2={191.19}
                            y2={566.37}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={566.37}
                            x2={191.19}
                            y2={571.92}
                        />
                        <line
                            className="cls-2"
                            x1={191.19}
                            y1={571.92}
                            x2={164.28}
                            y2={579.59}
                        />
                        <line className="cls-2" x1={164} y1={498.16} x2={190.9} y2={498.16} />
                        <line className="cls-2" x1={190.9} y1={498.16} x2={190.9} y2={492.61} />
                        <line className="cls-2" x1={190.9} y1={492.61} x2={164} y2={484.94} />
                        <line
                            className="cls-2"
                            x1={164.14}
                            y1={506.58}
                            x2={191.04}
                            y2={506.58}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={506.58}
                            x2={191.04}
                            y2={512.12}
                        />
                        <line
                            className="cls-2"
                            x1={191.04}
                            y1={512.12}
                            x2={164.14}
                            y2={519.8}
                        />
                        <line
                            className="cls-2"
                            x1={678.57}
                            y1={742.52}
                            x2={651.67}
                            y2={742.52}
                        />
                        <line
                            className="cls-2"
                            x1={651.67}
                            y1={742.52}
                            x2={651.67}
                            y2={736.97}
                        />
                        <line
                            className="cls-2"
                            x1={651.67}
                            y1={736.97}
                            x2={678.57}
                            y2={729.3}
                        />
                        <line
                            className="cls-2"
                            x1={678.43}
                            y1={750.94}
                            x2={651.52}
                            y2={750.94}
                        />
                        <line
                            className="cls-2"
                            x1={651.52}
                            y1={750.94}
                            x2={651.52}
                            y2={756.49}
                        />
                        <line
                            className="cls-2"
                            x1={651.52}
                            y1={756.49}
                            x2={678.43}
                            y2={764.16}
                        />
                        <line
                            className="cls-2"
                            x1={678.49}
                            y1={680.16}
                            x2={651.6}
                            y2={680.16}
                        />
                        <line className="cls-2" x1={651.6} y1={680.16} x2={651.6} y2={674.61} />
                        <line
                            className="cls-2"
                            x1={651.6}
                            y1={674.61}
                            x2={678.49}
                            y2={666.94}
                        />
                        <line
                            className="cls-2"
                            x1={678.35}
                            y1={688.58}
                            x2={651.45}
                            y2={688.58}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={688.58}
                            x2={651.45}
                            y2={694.13}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={694.13}
                            x2={678.35}
                            y2={701.8}
                        />
                        <line
                            className="cls-2"
                            x1={678.49}
                            y1={619.62}
                            x2={651.6}
                            y2={619.62}
                        />
                        <line className="cls-2" x1={651.6} y1={619.62} x2={651.6} y2={614.07} />
                        <line className="cls-2" x1={651.6} y1={614.07} x2={678.49} y2={606.4} />
                        <line
                            className="cls-2"
                            x1={678.35}
                            y1={628.04}
                            x2={651.45}
                            y2={628.04}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={628.04}
                            x2={651.45}
                            y2={633.59}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={633.59}
                            x2={678.35}
                            y2={641.26}
                        />
                        <line
                            className="cls-2"
                            x1={678.49}
                            y1={559.07}
                            x2={651.6}
                            y2={559.07}
                        />
                        <line className="cls-2" x1={651.6} y1={559.07} x2={651.6} y2={553.52} />
                        <line
                            className="cls-2"
                            x1={651.6}
                            y1={553.52}
                            x2={678.49}
                            y2={545.85}
                        />
                        <line
                            className="cls-2"
                            x1={678.35}
                            y1={567.49}
                            x2={651.45}
                            y2={567.49}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={567.49}
                            x2={651.45}
                            y2={573.04}
                        />
                        <line
                            className="cls-2"
                            x1={651.45}
                            y1={573.04}
                            x2={678.35}
                            y2={580.71}
                        />
                        <line
                            className="cls-2"
                            x1={678.65}
                            y1={499.28}
                            x2={651.74}
                            y2={499.28}
                        />
                        <line
                            className="cls-2"
                            x1={651.74}
                            y1={499.28}
                            x2={651.74}
                            y2={493.73}
                        />
                        <line
                            className="cls-2"
                            x1={651.74}
                            y1={493.73}
                            x2={678.65}
                            y2={486.06}
                        />
                        <line className="cls-2" x1={678.49} y1={507.7} x2={651.6} y2={507.7} />
                        <line className="cls-2" x1={651.6} y1={507.7} x2={651.6} y2={513.25} />
                        <line
                            className="cls-2"
                            x1={651.6}
                            y1={513.25}
                            x2={678.49}
                            y2={520.92}
                        />
                    </g>
                </g>
                <g id="Capa_5" data-name="Capa 5">
                    <rect
                    onClick={e => handlePlate(e)}
                        className="cls-2"
                        x={191.19}
                        y={741.4}
                        width={459.34}
                        height={9.54}
                    />
                    <rect
                    onClick={e => handlePlate(e)}
                        className="cls-2"
                        x={191.19}
                        y={678.48}
                        width={459.34}
                        height={9.54}
                    />
                    <rect
                    onClick={e => handlePlate(e)}
                        className="cls-2"
                        x={192.26}
                        y={619.06}
                        width={459.34}
                        height={9.54}
                    />
                    <rect
                    onClick={e => handlePlate(e)}
                        className="cls-2"
                        x={192.26}
                        y={558.51}
                        width={459.34}
                        height={9.54}
                    />
                    <rect
                    onClick={e => handlePlate(e)}
                        className="cls-2"
                        x={191.19}
                        y={497.6}
                        width={459.34}
                        height={9.54}
                    />
                </g>
                <rect
                    className="cls-1"
                    x={110.68}
                    y={138.98}
                    width={102.64}
                    height={237.33}
                />
                <path
                    className="cls-1"
                    d="m751.98,423.96V35.2c-2.83-3.41-6.02-6.56-9.32-9.55H213.32v404.81h534.33c1.51-2.13,2.98-4.28,4.33-6.5Z"
                />
                <circle className="cls-1" cx={734.92} cy={228.06} r={9.87} />
            </svg>

        </div>
    )
}

