import * as yup from 'yup'

export const shipSchema = yup.object().shape({
    enterprise: yup.string().required("Enterprise is required"),
    owner: yup.string().required("Owner is required"),
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    imoNumber: yup.string(),
    mmsi: yup.number().typeError("MMSI must be a number").required("MMSI is required"),
    callSign:yup.string().required("Call Sign is required"),
    flag: yup.string().required("Flag is required"),
    portReg: yup.string().required("Port Reg is required"),
    compass: yup.string().required("Compass is required"),
    mark: yup.string().required("Mark is required"),
    serialNumber: yup.string().required("Serial Number is required"),
    status:yup.string(),
})