import * as React from "react"
export const SvgComponent = ({ title, handleCircle, ...props }) => {

    return (
        <div className="svgContainer">
            <h2>{title}</h2>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 883.37 1005.29"
                {...props}
            >
                <defs>
                    <style>
                        {
                            `.cls-1 {  fill: #fff;}
                            .cls-1, .cls-2 {  stroke: #1d1d1b;  stroke-miterlimit: 10;}
                            .cls-2 {  fill: none;}
                            .cls-3 {  fill: #1d1d1b;  font-family: MyriadPro-Regular, 'Myriad Pro';  font-size: 21.94px;}
                            `
                        }
                    </style>
                </defs>
                <g id="Capa_1" data-name="Capa 1">
                    <path
                        className="cls-2"
                        d="m106.09,925.41c217.39,38.68,439.96,39.44,657.85,2.14"
                    />
                    <path
                        className="cls-2"
                        d="m105.71,94.04c217.89-37.29,440.46-36.53,657.85,2.14"
                    />
                    <line className="cls-2" x1={106.09} y1={94.01} x2={106.09} y2={925.41} />
                    <line className="cls-2" x1={763.56} y1={96.18} x2={763.56} y2={927.59} />
                    <line className="cls-2" x1={0.5} y1={1005.29} x2={0.5} />
                    <line className="cls-2" x1={882.87} y1={1005.29} x2={882.87} y2={5.29} />
                    <circle className="cls-2" cx={441.53} cy={47.76} r={7.89} />
                    <rect
                        className="cls-2"
                        x={300.91}
                        y={85.11}
                        width={279.5}
                        height={854.68}
                    />
                    <line className="cls-2" x1={367.85} y1={939.79} x2={367.82} y2={85.11} />
                    <line className="cls-2" x1={513.9} y1={939.79} x2={513.87} y2={85.11} />
                    <g>
                        <rect
                            className="cls-2"
                            x={368.25}
                            y={738.63}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.87}
                            y={738.63}
                            width={40.03}
                            height={31.7}
                        />
                        <text className="cls-3" transform="translate(499.92 761.8) rotate(-90)">
                            <tspan x={0} y={0}>
                                {"3"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.94}
                            y={674.39}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={472.95}
                            y={674.39}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(499.01 697.38) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"4"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.84}
                            y={610.15}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.46}
                            y={610.15}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(499.51 634.17) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"5"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.84}
                            y={545.9}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.15}
                            y={545.9}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(497.58 569.59) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"6"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.84}
                            y={481.66}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={472.54}
                            y={481.66}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(498.84 502.43) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"7"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.55}
                            y={417.41}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.87}
                            y={417.41}
                            width={40.03}
                            height={31.7}
                        />
                        <text className="cls-3" transform="translate(500.4 437.82) rotate(-90)">
                            <tspan x={0} y={0}>
                                {"8"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.84}
                            y={353.17}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473}
                            y={353.17}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(499.54 374.11) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"9"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.55}
                            y={867.12}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.7}
                            y={867.12}
                            width={40.03}
                            height={31.7}
                        />
                        <text className="cls-3" transform="translate(499.16 889) rotate(-90)">
                            <tspan x={0} y={0}>
                                {"1"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.33}
                            y={288.93}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={474.09}
                            y={288.93}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(500.27 316.32) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"10"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={367.84}
                            y={224.68}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={472.69}
                            y={224.68}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(499.71 252.82) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"11"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.33}
                            y={802.88}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.95}
                            y={802.88}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(498.63 824.98) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"2"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.71}
                            y={158.54}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.87}
                            y={158.54}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(500.89 186.96) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"12"}
                            </tspan>
                        </text>
                    </g>
                    <g>
                        <rect
                            className="cls-2"
                            x={368.25}
                            y={92.4}
                            width={145.65}
                            height={31.7}
                        />
                        <rect
                            className="cls-2"
                            x={473.87}
                            y={92.4}
                            width={40.03}
                            height={31.7}
                        />
                        <text
                            className="cls-3"
                            transform="translate(500.89 121.09) rotate(-90)"
                        >
                            <tspan x={0} y={0}>
                                {"13"}
                            </tspan>
                        </text>
                    </g>
                </g>
                <g id="Capa_3" data-name="Capa 3">
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={912.44} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={848.61} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={785.79} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={719.87} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={653.96} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.63} cy={588.05} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={527.64} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.63} cy={463.91} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={398.05} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.37} cy={333.92} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.63} cy={265.54} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.63} cy={200.6} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={548.63} cy={135.67} r={13.94} />
                    <circle className="cls-2" cx={445.64} cy={979.14} r={7.89} />
                </g>
                <g id="Capa_4" data-name="Capa 4">
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={912.44} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={848.61} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={785.79} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={719.87} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={653.96} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={335.15} cy={588.05} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={527.64} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={335.15} cy={463.91} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={398.05} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={334.89} cy={333.92} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={335.15} cy={265.54} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={335.15} cy={200.6} r={13.94} />
                    <circle onClick={e => handleCircle(e)} className="cls-1" cx={335.15} cy={135.67} r={13.94} />
                </g>
            </svg>

        </div>
    )
}
