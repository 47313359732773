import React, { createContext, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import config from '../config/config';
import { BsFillTrashFill } from 'react-icons/bs'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { shipSchema } from '../Validations/ship.validation';
import { TextInput } from '../Components/TextInput';
import { SelectInput } from '../Components/SelectInput';

const ModalContext = createContext();

const ModalProvider = ({ updateEnterprises, updateContacts, enterprises, children }) => {
    const [showShip, setShowShip] = useState(false);
    const [showEnterprise, setShowEnterprise] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const [loading, setLoading] = useState(false);

    // Contact Modal
    const [contact, setContact] = useState({});
    const [contactID, setContactID] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());

    // Enterprise Modal

    const [enterpriseName, setEnterpriseName] = useState('');
    const [enterpriseCountry, setEnterpriseCountry] = useState('');
    const [enterpriseNumber, setEnterpriseNumber] = useState('');
    const [enterpriseAdress, setEnterpriseAdress] = useState('');
    const [enterpriseEmail, setEnterpriseEmail] = useState('');
    const [enterpriseCuit, setEnterpriseCuit] = useState('');

    const [enterpriseID, setEnterpriseID] = useState(null);

    // History Modal 

    const [history, setHistory] = useState([]);
    const [historyMessage, setHistoryMessage] = useState('')
    const [historyID, setHistoryID] = useState(null);

    //#region Reset
    const resetContactForm = () => {
        setContactID(null)
        setContact({});
        setSelectedDate(new Date());
        reset()
    }

    const resetEnterpriseForm = () => {
        setEnterpriseAdress('')
        setEnterpriseCountry('')
        setEnterpriseCuit('')
        setEnterpriseEmail('')
        setEnterpriseName('')
        setEnterpriseNumber('')
        setEnterpriseID(null)
    }

    //#endregion



    const openContactModal = async (id = null) => {
        // console.log(id);
        if (id) {
            reset();
            setContactID(id);
            const response = await fetch(`${config.backURL}/api/admin/contact/${id}`, {
                credentials: 'include'
            })
            const json = await response.json()
            const contact = await json.payload

            setContact(contact);
            console.log(contact);
            setSelectedDate(contact?.alerts[0]?.date ? new Date(contact.alerts[0].date) : new Date())
        }
        setShowShip(true)
    };

    const openEnterpriseModal = async (id = null) => {
        console.log(id);
        if (id) {
            setEnterpriseID(id);
            const response = await fetch(`${config.backURL}/api/admin/enterprise/${id}`, {
                credentials: 'include'
            })

            const json = await response.json()
            const enterprise = await json.payload
            setEnterpriseName(enterprise?.name)
            setEnterpriseCountry(enterprise?.country)
            setEnterpriseNumber(enterprise?.phone)
            setEnterpriseAdress(enterprise?.adress)
            setEnterpriseEmail(enterprise?.email)
            setEnterpriseCuit(enterprise?.cuil)

        }
        setShowEnterprise(true)
    };

    const fetchHisotry = async (id) => {
        const response = await fetch(`${config.backURL}/api/admin/contact/${id}/history`, {
            credentials: 'include'

        })
        const json = await response.json();
        const messages = await json.payload
        setHistory(messages)
    }

    const openHistoryModal = async (id) => {
        setHistoryID(id)
        fetchHisotry(id)
        setShowHistory(true)
    }

    const closeModal = () => {
        contactID && resetContactForm()
        enterpriseID && resetEnterpriseForm()
        setContactID(null);
        setEnterpriseID(null)
        setHistoryID(null)
        setShowShip(false)
        setShowEnterprise(false)
        setShowHistory(false)
    };

    //#region Submit
    // Contact Form Hook
    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(shipSchema)
    });

    // Contact Submit
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            data.alertDate = selectedDate
            const method = contactID ? "PUT" : "POST";
            const uri = `${config.backURL}/api/admin/contact${method === 'POST' ? '' : '/' + contactID}`
            const response = await fetch(uri, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',

                body: JSON.stringify(data)
            })
            if (response.ok) {
                updateContacts()
                resetContactForm()
                closeModal()
            }
        }
        finally {
            setLoading(false)
        }
    }
    // Enterprise Submit
    const handleEnterpriseSubmit = async (method) => {
        const data = JSON.stringify({
            name: enterpriseName,
            email: enterpriseEmail,
            country: enterpriseCountry,
            cuit: enterpriseCuit,
            adress: enterpriseAdress,
            phone: enterpriseNumber
        })
        const uri = `${config.backURL}/api/admin/enterprise${method === 'POST' ? '' : '/' + enterpriseID}`
        try {
            const response = await fetch(uri, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: data
            })
            const json = await response.json()
            if (response.ok) {
                resetEnterpriseForm()
                updateEnterprises()
                closeModal()
            } else {
                console.error(json.message);
            }
        } catch {
        }
    }

    const handleHistorySubmit = async () => {
        try {
            const response = await fetch(`${config.backAPI}/contact/${historyID}/history`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',

                body: JSON.stringify({ message: historyMessage })
            })
            const json = await response.json()
            if (response.ok) {
                setHistoryMessage('')
                fetchHisotry(historyID)
            }
        } catch {
        }
    }

    const handleHistoryDelete = async (e) => {
        const el = e.target.closest('svg');
        const id = el.getAttribute('data-id');
        try {
            const response = await fetch(`${config.backAPI}/contact/${historyID}/history/${id}`, {
                method: 'DELETE',
                credentials: 'include',

            })
            const json = await response.json()
            if (response.ok) {
                fetchHisotry(historyID)
            }
        } catch {
        }
    }
    //#endregion
    // Modals Content

    //#region Modal Content
    const modalShipContent = (
        <Modal
            show onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title style={styles.title}>{contactID ? 'Update Ship' : 'Add Ship'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="contactForm" className='row' onSubmit={handleSubmit(onSubmit)}>

                    {/* Enterprise  */}

                    {/* <div className="form-group">
                        <select className="form-control" id="enterprise" name="enterprise" aria-describedby="enterprise"
                            value={contact?.enterprise}
                            onChange={(e) => setValue("enterprise", e.target.value)}
                            {...register("enterprise")}
                        >
                            <option selected value={''} disabled>Enterprise</option>
                            {enterprises?.map(el =>
                                <option key={`contactModal - ${el.name}`} value={el.name}>{el.name}</option>
                            )}
                        </select>
                        <p className='error'>{errors.enterprise?.message}</p>
                    </div> */}
                    <SelectInput
                        name="enterprise"
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        value={contact?.enterprise}
                    >
                        <option value="" selected disabled>Enterprise</option>
                        {enterprises?.map(el => (
                            <option key={`contactModal-${el.name}`} value={el.name}>
                                {el.name}
                            </option>
                        ))}
                    </SelectInput>

                    {/* Owner  */}
                    <TextInput setValue={setValue} value={contact?.owner} className={"form-control"} name={'owner'} placeholder={'Owner'} register={register} errors={errors} />

                    {/* Name  */}
                    <TextInput setValue={setValue} value={contact?.name} className={"form-control"} name={'name'} placeholder={'Name'} register={register} errors={errors} />

                    {/* Email  */}
                    <TextInput setValue={setValue} value={contact?.email} className={"form-control"} name={'email'} placeholder={'Email'} register={register} errors={errors} />

                    {/* IMO Number  */}
                    <TextInput setValue={setValue} value={contact?.imoNumber} className={"form-control"} small name={'imoNumber'} placeholder={'IMO Number'} register={register} errors={errors} />

                    {/* MMSI  */}
                    <TextInput setValue={setValue} value={contact?.mmsi} className={"form-control"} small name={'mmsi'} placeholder={'MMSI'} register={register} errors={errors} />


                    {/* Call Sign  */}
                    <TextInput setValue={setValue} value={contact?.callSign} className={"form-control"} small name={'callSign'} placeholder={'Call Sign'} register={register} errors={errors} />

                    {/* Flag (Country)  */}
                    <TextInput setValue={setValue} value={contact?.flag} className={"form-control"} small name={'flag'} placeholder={'Flag'} register={register} errors={errors} />

                    {/* Port Reglamentation  */}
                    <TextInput setValue={setValue} value={contact?.portReg} className={"form-control"} small name={'portReg'} placeholder={'Port Reg'} register={register} errors={errors} />

                    {/* Compass  */}
                    <TextInput setValue={setValue} value={contact?.compass} className={"form-control"} small name={'compass'} placeholder={'Compass'} register={register} errors={errors} />

                    {/* Mark  */}
                    <TextInput setValue={setValue} value={contact?.mark} className={"form-control"} name={'mark'} placeholder={'Mark'} register={register} errors={errors} />

                    {/* Serial Number  */}
                    <TextInput setValue={setValue} value={contact?.serialNumber} className={"form-control"} name={'serialNumber'} placeholder={'Serial Number'} register={register} errors={errors} />

                    {/* Status */}

                    <SelectInput
                        name="status"
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        value={contact?.status}
                    >
                        <option disabled selected value=''>Select status</option>
                        <option value="newShip">Nuevo Barco</option>
                        <option value="waiting">Esperando Info</option>
                        <option value="compensating">En Compensación</option>
                        <option value="signing">Esperando Firma</option>
                        <option value="billing">En Facturacion</option>
                        <option value="closed">Cerrado</option>
                    </SelectInput>
                    <div className=' datePicker'>
                        <p style={{ color: '#858796', fontSize: 18 }}>Alert</p>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            showIcon
                            fixedHeight
                            minDate={new Date()}
                        />

                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ fontSize: 16 }} onClick={closeModal}>
                    Close
                </Button>
                <Button style={styles.button} type='submit' form='contactForm' >
                    {contactID ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const modalEnterpriseContent = (
        <Modal
            show onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title style={styles.title}>Add Enterprise</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="enterpriseForm">
                    <div className="form-group">
                        <input type="text" className="form-control" id="name" name="name" aria-describedby="name" placeholder="Name"
                            value={enterpriseName}
                            onChange={(e) => setEnterpriseName(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" id="country" name="country" aria-describedby="country" placeholder="Country"
                            value={enterpriseCountry}
                            onChange={(e) => setEnterpriseCountry(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" id="phone" name="phone" aria-describedby="phone" placeholder="Phone Number"
                            value={enterpriseNumber}
                            onChange={(e) => setEnterpriseNumber(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" id="adress" name="adress" aria-describedby="adress" placeholder="Adress"
                            value={enterpriseAdress}
                            onChange={(e) => setEnterpriseAdress(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <input type="email" className="form-control" id="email" name="email" aria-describedby="email" placeholder="Email"
                            value={enterpriseEmail}
                            onChange={(e) => setEnterpriseEmail(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <input type="text" className="form-control" id="cuit" name="cuit" aria-describedby="cuit" placeholder="Cuit"
                            value={enterpriseCuit}
                            onChange={(e) => setEnterpriseCuit(e.target.value)}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ fontSize: 16 }} variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button style={styles.button} onClick={() => handleEnterpriseSubmit(enterpriseID ? 'PUT' : 'POST')} >
                    {enterpriseID ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const modalHistoryContent = (
        <Modal
            show onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title style={styles.title}>History</Modal.Title>
            </Modal.Header>
            <Modal.Body className='historyCont'>
                <ul>
                    {history?.map((log, index) => (
                        <div key={`${index} - ${historyID} - ${log.date} - DATE`} >
                            <li>{log.message}</li>
                            <p>{log.date}</p>
                            <BsFillTrashFill color='#DB0035' onClick={e => handleHistoryDelete(e)} data-id={log.id} />
                        </div>
                    ))}
                </ul>
                <form>
                    <textarea id="historyContact"
                        value={historyMessage}
                        onChange={e => setHistoryMessage(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                handleHistorySubmit();
                            }
                        }}
                    />
                    <Button style={styles.button} onClick={handleHistorySubmit}>
                        Add
                    </Button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ fontSize: 16 }} onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
    //#endregion

    return (
        <ModalContext.Provider value={{ openContactModal, openEnterpriseModal, openHistoryModal, closeModal }}>
            {children}
            {showShip && modalShipContent}
            {showEnterprise && modalEnterpriseContent}
            {showHistory && modalHistoryContent}

        </ModalContext.Provider>
    );
}

export { ModalContext, ModalProvider };

const styles = {
    title: {
        fontSize: 20,
        color: '#858796'
    },
    button: {
        fontSize: 16,
        backgroundColor: '#6A5BE2',
        borderColor: '#6A5BE2'
    }
}