import React, { useState } from "react";
import { SvgComponent as SauraHeeling } from "./SauraHeelings";
import { SvgComponent as SauraFA } from "./SauraFA";
import { SvgComponent as SauraBoxesPort } from "./SauraBoxPort";
import { SvgComponent as SauraBoxesStbd } from "./SauraBoxStbd";
import { SvgComponent as SauraFlinders } from "./SauraFlinders";
import { SvgComponent as SauraAthwartship } from "./SauraAth";

export const SauraContainer = () => {

    const changeCircleColor = (e, value) => {

        switch (value) {
            case 1:
                e.target.style.fill = '#e62e1e'
                break;

            case 2:
                e.target.style.fill = '#531ee6'
                break;

            default:
                e.target.style.fill = '#fff'
                break;
        }
    }

    const changePlateColor = (e, value) => {

        switch (value) {
            case 1:
                e.target.style.fill = '#000'
                break;

            default:
                e.target.style.fill = '#fff'
                break;

        }
    }

    const handlePlate = (e) => {
        let value = Number(e.target.getAttribute('data-value'))

        if (!value) e.target.setAttribute('data-value', 0)

        value === 1 ? value = 0 : value++

        e.target.setAttribute('data-value', value)

        changePlateColor(e, value)
    }

    const handleCircle = (e) => {
        let value = Number(e.target.getAttribute('data-value'))

        if (!value) e.target.setAttribute('data-value', 0)

        value === 2 ? value = 0 : value++

        e.target.setAttribute('data-value', value)

        changeCircleColor(e, value)
    }

    const [flip, setFlip] = useState(false);

    return (
        <div className="SauraKeiki">
            <SauraFA title="Fore and After Magnets" handleCircle={handleCircle} className='compassSvg foreAfter' />
            <SauraAthwartship title='Athwartships' handleCircle={handleCircle} className='compassSvg ath' />
            <SauraHeeling title="Heelings" className='compassSvg heelings' />
            <SauraFlinders title='Flinders' handlePlate={handlePlate} className='compassSvg flinders' />
            <div style={styles.cont}>
                <label style={styles.label} htmlFor="flip">Flip boxes scale</label>
                <input style={styles.input} type="checkbox" checked={flip} name="flip" id=""
                    onChange={(e) => setFlip(!flip)} />
            </div>
            <SauraBoxesPort title='Boxes Port' handlePlate={handlePlate} flip={flip} className='compassSvg boxes' />
            <SauraBoxesStbd title='Boxes Stbd' handlePlate={handlePlate} flip={flip} className='compassSvg boxes' />
        </div>
    )
}

const styles = {
    cont:{
        display: "flex",
        alignItems: "center"
    },
    label: {
        fontSize: 22,
        fontWeight: "bold"
    },
    input: {
        appearance: "auto",
        marginLeft: 5,
    }
}





