import React from 'react'
import { PolicyENG } from "./Policy/PolicyENG";
import { PolicyESP } from "./Policy/PolicyESP";


export const Policy = () => {
  return (
    <>
        <PolicyENG />
        <hr />
        <PolicyESP />
    </>
  )
}
